import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MonacoEditor from "@monaco-editor/react";
import {
  Play,
  Settings,
  Download,
  Copy,
  Clock,
  Database,
  HardDrive,
  Terminal,
  ChevronDown,
  LogOut,
  User,
  Mail,
  Sun,
  Moon,
  X,
} from "lucide-react";

// Judge0 API configuration
const RAPID_API_HOST = "judge0-ce.p.rapidapi.com";
const RAPID_API_KEY = "c076083edemshaa761dfa52636ebp1b58d6jsn5d8b8d429c91"; // Replace with your actual API key

const languageOptions = [
  { id: 54, name: "C++", language: "cpp" },
  { id: 62, name: "Java", language: "java" },
  { id: 71, name: "Python", language: "python" },
  { id: 63, name: "JavaScript", language: "javascript" },
  { id: 51, name: "C", language: "c" },
  { id: 78, name: "Kotlin", language: "kotlin" },
  { id: 82, name: "SQL", language: "sql" },
];

const defaultCode = {
  python: '# Write your Python code here\nprint("Hello, World!")',
  cpp: '#include <iostream>\n\nint main() {\n    std::cout << "Hello, World!" << std::endl;\n    return 0;\n}',
  java: 'public class Main {\n    public static void main(String[] args) {\n        System.out.println("Hello, World!");\n    }\n}',
  javascript:
    '// Write your JavaScript code here\nconsole.log("Hello, World!");',
  c: '#include <stdio.h>\n\nint main() {\n    printf("Hello, World!\\n");\n    return 0;\n}',
  kotlin: 'fun main() {\n    println("Hello, World!")\n}',
  sql: 'SELECT "Hello, World!" as greeting;',
};

export default function EditorPage() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [code, setCode] = useState(defaultCode.python);
  const [language, setLanguage] = useState(languageOptions[2]); // Python default
  const [theme, setTheme] = useState("vs-dark");
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [stats, setStats] = useState(null);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [fontSize, setFontSize] = useState(14);

  useEffect(() => {
    const userInfo = localStorage.getItem("user");
    if (!userInfo) {
      navigate("/");
      return;
    }
    setUser(JSON.parse(userInfo));
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  const getInitials = (name) => {
    return (
      name
        ?.split(" ")
        .map((word) => word[0])
        .join("")
        .toUpperCase() || "U"
    );
  };

  const handleLanguageChange = (newLang) => {
    setLanguage(newLang);
    setCode(defaultCode[newLang.language] || "// Start coding here");
    setShowLanguageMenu(false);
  };

  const ProfileModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-lg w-96 p-6 relative">
        {/* Close button */}
        <button
          onClick={() => setShowProfileModal(false)}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <X className="h-5 w-5" />
        </button>

        {/* Profile Content */}
        <div className="flex flex-col items-center">
          {/* Large Profile Icon */}
          <div className="w-24 h-24 rounded-full bg-violet-600 flex items-center justify-center text-3xl font-bold mb-4">
            {getInitials(user?.name)}
          </div>

          {/* User Details */}
          <div className="space-y-4 w-full">
            <div className="flex items-center space-x-3 px-4 py-3 bg-gray-700 rounded-lg">
              <User className="h-5 w-5 text-violet-400" />
              <div>
                <div className="text-sm text-gray-400">Name</div>
                <div className="font-medium">{user?.name}</div>
              </div>
            </div>

            <div className="flex items-center space-x-3 px-4 py-3 bg-gray-700 rounded-lg">
              <Mail className="h-5 w-5 text-violet-400" />
              <div>
                <div className="text-sm text-gray-400">Email</div>
                <div className="font-medium">{user?.email}</div>
              </div>
            </div>

            <button
              onClick={handleLogout}
              className="w-full mt-6 flex items-center justify-center space-x-2 px-4 py-2 bg-red-600 hover:bg-red-700 rounded-lg transition-colors"
            >
              <LogOut className="h-5 w-5" />
              <span>Sign Out</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const SettingsMenu = () => (
    <div className="z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5">
      <div className="py-1">
        <button
          onClick={() => setTheme(theme === "vs-dark" ? "light" : "vs-dark")}
          className="flex items-center space-x-2 w-full px-4 py-2 text-sm text-left hover:bg-gray-600"
        >
          {theme === "vs-dark" ? (
            <>
              <Sun className="h-4 w-4" />
              <span>Light Theme</span>
            </>
          ) : (
            <>
              <Moon className="h-4 w-4" />
              <span>Dark Theme</span>
            </>
          )}
        </button>

        <div className="px-4 py-2 text-sm">
          <label className="block text-gray-400 mb-2">Font Size</label>
          <input
            type="range"
            min="12"
            max="24"
            value={fontSize}
            onChange={(e) => setFontSize(Number(e.target.value))}
            className="w-full"
          />
          <div className="text-right text-gray-400">{fontSize}px</div>
        </div>
      </div>
    </div>
  );

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code);
    // You can add a toast notification here
  };

  const downloadCode = () => {
    const element = document.createElement("a");
    const file = new Blob([code], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `code.${language.language}`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const runCode = async () => {
    setIsRunning(true);
    setOutput("Compiling...");
    setStats(null);

    try {
      // First API call to submit the code
      const submitResponse = await axios.post(
        "https://judge0-ce.p.rapidapi.com/submissions",
        {
          source_code: code,
          language_id: language.id,
          stdin: input,
        },
        {
          headers: {
            "content-type": "application/json",
            "X-RapidAPI-Host": RAPID_API_HOST,
            "X-RapidAPI-Key": RAPID_API_KEY,
          },
        },
      );

      const token = submitResponse.data.token;

      // Poll for results
      let result;
      while (true) {
        const { data } = await axios.get(
          `https://judge0-ce.p.rapidapi.com/submissions/${token}`,
          {
            headers: {
              "X-RapidAPI-Host": RAPID_API_HOST,
              "X-RapidAPI-Key": RAPID_API_KEY,
            },
          },
        );

        if (data.status.id > 2) {
          // Status > 2 means finished processing
          result = data;
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second before polling again
      }

      setStats({
        time: result.time,
        memory: result.memory,
        status: result.status.description,
      });

      if (result.status.id === 3) {
        // Successful execution
        setOutput(
          result.stdout || "Program executed successfully with no output.",
        );
      } else {
        setOutput(
          result.stderr ||
            result.compile_output ||
            "An error occurred during execution.",
        );
      }
    } catch (error) {
      setOutput("Error connecting to the execution service. Please try again.");
      console.error("Execution error:", error);
    } finally {
      setIsRunning(false);
    }
  };

  if (!user) return null;
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Navbar */}
      <nav className="bg-gray-800 border-b border-gray-700">
        <div className="max-w-full mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center space-x-4">
              <Terminal className="h-8 w-8 text-violet-400" />
              <span className="text-xl font-bold">CodeFlow</span>

              {/* Language Selector */}
              <div className="relative ml-6">
                <button
                  onClick={() => setShowLanguageMenu(!showLanguageMenu)}
                  className="flex items-center space-x-2 px-3 py-2 rounded-md bg-gray-700 hover:bg-gray-600"
                >
                  <span>{language.name}</span>
                  <ChevronDown className="h-4 w-4" />
                </button>

                {showLanguageMenu && (
                  <div className="absolute top-full mt-2 w-48 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5 z-50">
                    <div className="py-1">
                      {languageOptions.map((lang) => (
                        <button
                          key={lang.id}
                          className="flex items-center space-x-2 w-full px-4 py-2 text-sm text-left hover:bg-gray-600"
                          onClick={() => handleLanguageChange(lang)}
                        >
                          <span>{lang.name}</span>
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* User Profile */}
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setShowProfileModal(true)}
                className="flex items-center space-x-3 hover:bg-gray-700 px-3 py-2 rounded-md transition-colors"
              >
                <div className="w-8 h-8 rounded-full bg-violet-600 flex items-center justify-center">
                  <span className="font-semibold">
                    {getInitials(user?.name)}
                  </span>
                </div>
                <span className="hidden md:block">{user?.name}</span>
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex flex-col md:flex-row h-[calc(100vh-4rem)]">
        {/* Editor Section */}
        <div className="flex-1 p-4">
          <div className="h-full flex flex-col">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center space-x-2">
                <button
                  onClick={runCode}
                  disabled={isRunning}
                  className={`flex items-center space-x-2 px-4 py-2 rounded-md ${
                    isRunning
                      ? "bg-gray-600 cursor-not-allowed"
                      : "bg-violet-600 hover:bg-violet-700"
                  }`}
                >
                  <Play className="h-4 w-4" />
                  <span>{isRunning ? "Running..." : "Run"}</span>
                </button>
              </div>

              <div className="flex items-center space-x-2">
                <button
                  onClick={copyToClipboard}
                  className="p-2 rounded-md hover:bg-gray-700"
                  title="Copy Code"
                >
                  <Copy className="h-4 w-4" />
                </button>
                <button
                  onClick={downloadCode}
                  className="p-2 rounded-md hover:bg-gray-700"
                  title="Download Code"
                >
                  <Download className="h-4 w-4" />
                </button>
                <div className="relative">
                  <button
                    onClick={() => setShowSettingsMenu(!showSettingsMenu)}
                    className="p-2 rounded-md hover:bg-gray-700"
                    title="Settings"
                  >
                    <Settings className="h-4 w-4" />
                  </button>
                  {showSettingsMenu && <SettingsMenu />}
                </div>
              </div>
            </div>

            <div className="flex-1 overflow-hidden rounded-lg border border-gray-700">
              <MonacoEditor
                height="100%"
                language={language.language}
                value={code}
                theme={theme}
                onChange={setCode}
                options={{
                  minimap: { enabled: false },
                  fontSize: fontSize,
                  scrollBeyondLastLine: false,
                  automaticLayout: true,
                }}
              />
            </div>
          </div>
        </div>

        {/* Input/Output Section */}
        <div className="w-full md:w-96 bg-gray-800 p-4 flex flex-col">
          {/* Execution Stats */}
          {stats && (
            <div className="mb-4 grid grid-cols-3 gap-4">
              <div className="bg-gray-700 p-3 rounded-lg">
                <div className="flex items-center space-x-2 text-sm text-gray-400">
                  <Clock className="h-4 w-4" />
                  <span>Time</span>
                </div>
                <div className="mt-1 text-lg">{stats.time || 0} s</div>
              </div>
              <div className="bg-gray-700 p-3 rounded-lg">
                <div className="flex items-center space-x-2 text-sm text-gray-400">
                  <HardDrive className="h-4 w-4" />
                  <span>Memory</span>
                </div>
                <div className="mt-1 text-lg">{stats.memory || 0} KB</div>
              </div>
              <div className="bg-gray-700 p-3 rounded-lg">
                <div className="flex items-center space-x-2 text-sm text-gray-400">
                  <Database className="h-4 w-4" />
                  <span>Status</span>
                </div>
                <div className="mt-1 text-sm">{stats.status || "N/A"}</div>
              </div>
            </div>
          )}

          {/* Input */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Input
            </label>
            <textarea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="w-full h-32 bg-gray-700 border border-gray-600 rounded-lg p-3 text-white"
              placeholder="Program input here..."
            />
          </div>

          {/* Output */}
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Output
            </label>
            <div className="h-[calc(100%-2rem)] bg-gray-700 border border-gray-600 rounded-lg p-3 overflow-auto">
              <pre className="text-sm font-mono whitespace-pre-wrap">
                {output}
              </pre>
            </div>
          </div>
        </div>
      </div>
      {showProfileModal && <ProfileModal />}
    </div>
  );
}
