import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC7cr4LsV7xhP6sqTG558SZismlZj3PBHA",

  authDomain: "codeeditor-b5a92.firebaseapp.com",

  projectId: "codeeditor-b5a92",

  storageBucket: "codeeditor-b5a92.firebasestorage.app",

  messagingSenderId: "1068068950467",

  appId: "1:1068068950467:web:b98b4db8301906068dad76",

  measurementId: "G-LDVC5QM8GP",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const signInWithGoogle = () => {
  return signInWithPopup(auth, provider);
};

export { auth, signInWithGoogle };
