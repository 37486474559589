import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithGoogle } from "./firebase";
import { toast } from "react-hot-toast";
import {
  Code,
  Terminal,
  Share2,
  Layers,
  GitBranch,
  Rocket,
} from "lucide-react";

export default function LandingPage() {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleGoogleLogin = async () => {
    setIsLoading(true);
    try {
      const result = await signInWithGoogle();
      const userInfo = {
        email: result.user.email,
        name: result.user.displayName,
        photoURL: result.user.photoURL,
        uid: result.user.uid,
      };
      localStorage.setItem("user", JSON.stringify(userInfo));
      toast.success("Successfully logged in!");
      navigate("/editor");
    } catch (err) {
      console.error("Google login failed", err);
      toast.error(err.message || "Failed to sign in with Google");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900">
      {/* Navbar */}
      <nav
        className={`fixed w-full z-50 transition-all duration-300 ${
          isScrolled
            ? "bg-gray-900/90 backdrop-blur-md shadow-lg"
            : "bg-transparent"
        }`}
      >
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center space-x-2">
              <Code className="h-8 w-8 text-violet-400" />
              <span className="text-2xl font-bold text-white">CodeFlow</span>
            </div>
            <div className="hidden md:flex items-center space-x-8">
              <a
                href="#features"
                className="text-gray-300 hover:text-violet-400 transition-colors"
              >
                Features
              </a>
              <a
                href="#workflow"
                className="text-gray-300 hover:text-violet-400 transition-colors"
              >
                Workflow
              </a>
              <button
                onClick={handleGoogleLogin}
                disabled={isLoading}
                className={`px-6 py-2 rounded-full transition-all
                ${isLoading ? "opacity-70 cursor-not-allowed" : "hover:scale-105"} 
                bg-violet-600 hover:bg-violet-700 text-white font-medium`}
              >
                {isLoading ? "Signing in..." : "Start Coding"}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="pt-32 pb-20 px-4">
        <div className="max-w-6xl mx-auto text-center">
          <div className="flex justify-center mb-6">
            <Terminal className="h-16 w-16 text-violet-400" />
          </div>
          <h1 className="text-6xl font-bold text-white mb-6">
            Code Anywhere.
            <span className="block mt-2 text-transparent bg-clip-text bg-gradient-to-r from-violet-400 to-purple-300">
              Deploy Everywhere.
            </span>
          </h1>
          <p className="text-xl text-gray-300 mb-10 max-w-2xl mx-auto">
            A powerful online code editor with real-time collaboration,
            multi-language support, and instant deployment capabilities.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <button
              onClick={handleGoogleLogin}
              disabled={isLoading}
              className={`px-8 py-4 rounded-lg text-lg font-semibold transition-all
              ${isLoading ? "opacity-70 cursor-not-allowed" : "hover:scale-105"} 
              bg-violet-600 hover:bg-violet-700 text-white`}
            >
              {isLoading ? "Signing in..." : "Start Coding Now"}
            </button>
            <a
              href="#features"
              className="px-8 py-4 rounded-lg text-lg font-semibold border-2 border-violet-400 
              text-violet-400 hover:bg-violet-400/10 transition-all"
            >
              Explore Features
            </a>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-20 bg-gray-900/50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-white">
              Powerful Features for Modern Development
            </h2>
            <p className="mt-4 text-gray-400 text-lg">
              Everything you need to code, collaborate, and deploy
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Code className="w-8 h-8 text-violet-400" />,
                title: "Multi-Language Support",
                description:
                  "Write code in over 30+ programming languages with syntax highlighting and auto-completion",
              },
              {
                icon: <Share2 className="w-8 h-8 text-violet-400" />,
                title: "Real-time Collaboration",
                description:
                  "Code together with your team in real-time with built-in chat and commenting",
              },
              {
                icon: <GitBranch className="w-8 h-8 text-violet-400" />,
                title: "Git Integration",
                description:
                  "Seamless Git integration for version control and team collaboration",
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="p-6 rounded-xl bg-gray-800/50 border border-gray-700 
                hover:border-violet-500 transition-all duration-300"
              >
                <div className="mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-3 text-white">
                  {feature.title}
                </h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Workflow Section */}
      <section id="workflow" className="py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-white">Simple Workflow</h2>
            <p className="mt-4 text-gray-400 text-lg">
              Get started in minutes, deploy in seconds
            </p>
          </div>

          <div className="grid md:grid-cols-4 gap-8">
            {[
              {
                icon: <Terminal className="w-8 h-8 text-violet-400" />,
                title: "Write Code",
                description: "Start coding in your preferred language",
              },
              {
                icon: <Layers className="w-8 h-8 text-violet-400" />,
                title: "Build Project",
                description: "Build and test your project in the cloud",
              },
              {
                icon: <GitBranch className="w-8 h-8 text-violet-400" />,
                title: "Version Control",
                description: "Commit and manage your code versions",
              },
              {
                icon: <Rocket className="w-8 h-8 text-violet-400" />,
                title: "Deploy",
                description: "Deploy your application with one click",
              },
            ].map((step, index) => (
              <div
                key={index}
                className="p-6 rounded-xl bg-gray-800/30 border border-gray-700 
                hover:border-violet-500 transition-all duration-300"
              >
                <div className="mb-4">{step.icon}</div>
                <h3 className="text-xl font-semibold mb-3 text-white">
                  {step.title}
                </h3>
                <p className="text-gray-400">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
